import React from "react"
import PropTypes from "prop-types"
import { Typography } from "@mui/material"

const SectionTitle = ({ subtitle, children, ...props }) => {
  return (
    <Typography
      variant={subtitle ? "h5" : "h4"}
      color={subtitle ? "primary" : "secondary"}
      sx={{ textTransform: "uppercase", mb: 1 }}
      {...props}
    >
      <strong>{children}</strong>
    </Typography>
  )
}

SectionTitle.propTypes = {
  children: PropTypes.node,
}

export default SectionTitle
